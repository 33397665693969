import React from "react";

import InputText from "./InputText";
import OptionsNumber from "./OptionNumber";
import OptionsSecond from "./OptionsSecond";

const capitalizeFirstLetter = (str) => {
  try {
    // Check if the string is empty
    if (str.length === 0) return str;

    // Capitalize the first letter and concatenate it with the rest of the string
    return str.charAt(0).toUpperCase() + str.slice(1);
  } catch (err) {
    return str;
  }
};

const QuestionnaireResponseOutput = ({
  questionnaireData,
  currentQuestion,
  response,
  questionnaireScreen,
}) => {
  console.log(response);

  // useEffect(() => {
  //   if (weeklyResponse) {
  //     setResponse(weeklyResponse);
  //   }
  // }, []);

  return (
    <div>
      <div className=" h-fit w-[400px]  px-4 pb-8 ">
        <h2
          className={`py-[25px] font-sfpro text-[32px] font-medium leading-[40px] ${
            questionnaireData[questionnaireScreen - 1].text
          }  `}
        >
          {questionnaireData[questionnaireScreen - 1].heading}
        </h2>
        <div className="flex flex-col gap-4">
          {currentQuestion &&
            currentQuestion.map((ques) => {
              return (
                <div className="rounded-xl  border border-black px-[13px] py-[15px]">
                  <div className="mb-3 flex w-full ">
                    {/* Question */}
                    <div className="w-fit">
                      <h1 className="text-[14px]  ">
                        {`${capitalizeFirstLetter(ques?.content)}${
                          ques?.isRequired ? " *" : ""
                        }`}
                      </h1>
                      {/* Description */}
                      {(ques.code === "WKR5" ||
                        ques.code === "WKR6" ||
                        ques.code === "WKR7" ||
                        ques.code === "WKR8") && (
                        <p className="my-[2px] space-x-2 text-[10px] text-black">
                          {capitalizeFirstLetter(ques?.description)}
                        </p>
                      )}
                    </div>
                  </div>{" "}
                  {(ques?.inputType?.toUpperCase() === "SINGLECHOICE" ||
                    ques?.inputType?.toUpperCase() === "MULTICHOICE" ||
                    ques?.inputType?.toUpperCase() ===
                      "SINGLEC?HOICEANDOTHER") &&
                    ques?.options.length <= 4 && (
                      <OptionsSecond
                        questionCode={ques?.code}
                        options={ques?.options}
                        MCQType={ques?.inputType}
                        target={ques?.target}
                        response={Object.keys(response)?.length > 0 && response}
                      />
                    )}
                  {ques?.text === true && (
                    <div className="mt-2 w-full">
                      <input
                        className=" flex h-[67px] w-full items-start  rounded-xl bg-white-opacity-08 p-3 text-start font-light placeholder:absolute placeholder:top-3 placeholder:mb-3 placeholder:text-[14px]"
                        type="text"
                        placeholder="Type your text here..."
                      />
                    </div>
                  )}
                  {ques?.options?.length === 5 && (
                    <OptionsNumber
                      questionCode={ques?.code}
                      options={ques?.options}
                      MCQType={ques?.inputType}
                      target={ques?.target}
                      response={Object.keys(response)?.length > 0 && response}
                    />
                  )}
                  {ques.code !== "WKR1" &&
                    ques.code !== "WKR4" &&
                    ques.code !== "WKR6" &&
                    ques.code !== "WKR7" &&
                    ques.code !== "WKR8" &&
                    ques.code !== "WKR3" &&
                    ques.code !== "WKR5" && (
                      <InputText
                        questionCode={ques?.code}
                        response={Object.keys(response)?.length > 0 && response}
                      />
                    )}
                  {ques.code === "WKR5" &&
                    response?.find((elem) => elem?.code === "WKR5")
                      ?.value?.[0] === "YES" && (
                      <InputText
                        questionCode={ques?.code}
                        response={Object.keys(response)?.length > 0 && response}
                      />
                    )}
                  {ques.code === "WKR3" &&
                    response?.find((elem) => elem?.code === "WKR3")
                      ?.value?.[0] === "YES" && (
                      <InputText
                        questionCode={ques?.code}
                        response={Object.keys(response)?.length > 0 && response}
                      />
                    )}
                </div>
              );
            })}
        </div>
        {questionnaireScreen === 3 &&
          response?.find((elem) => elem?.code === "WKR13") && (
            <div className="flex flex-col gap-4">
              <div className="mt-6 rounded-xl bg-black-opacity-45 p-4">
                <h4 className="font-sfpro text-sm leading-[18px]">
                  Progress Photos
                </h4>

                <div className="mt-[14px] flex flex-col justify-center gap-4">
                  {response
                    ?.find((elem) => elem?.code === "WKR13")
                    .value.map((item) => (
                      <>
                        {" "}
                        <img
                          src={item}
                          alt="img"
                          className={` h-full rounded-[8px]  `}
                        />
                      </>
                    ))}
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default QuestionnaireResponseOutput;
