import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import QuestionnaireResponseOutput from "./WeeklyCheckinComponent/ResponseOutput";
import WeeklyCheckinResult from "./WeeklyCheckinComponent/WeeklyCheckinResult";

const getPreviousWeeks = (givenWeekStr) => {
  // Helper function to parse a string like "6Jan" into a Date object
  const parseDate = (dateStr) => {
    const monthMap = {
      Jan: 0,
      Feb: 1,
      Mar: 2,
      Apr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Aug: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dec: 11,
    };

    const day = parseInt(dateStr.slice(0, -3), 10);
    const month = monthMap[dateStr.slice(-3)];
    const year = 2025; // Hardcoding year for simplicity

    return new Date(year, month, day);
  };

  const formatDate = (startdate, enddate) => {
    const monthStartStr = startdate.toLocaleString("default", {
      month: "short",
    });
    const monthEndStr = enddate.toLocaleString("default", { month: "short" });

    return `${startdate.getDate()}${monthStartStr}-${enddate.getDate()}${monthEndStr}-${enddate.getFullYear()}`;
  };

  // Split the given week string into start and end dates
  const [startStr, endStr] = givenWeekStr.split("-");

  // Parse the start and end dates
  let startDate = parseDate(startStr);
  let endDate = parseDate(endStr);

  const weeks = [];

  // Add the given week, adjusting for correct year
  weeks.push(formatDate(startDate, endDate));

  // Add the previous 5 weeks
  for (let i = 1; i <= 5; i++) {
    const prevStartDate = new Date(
      startDate.getTime() - i * 7 * 24 * 60 * 60 * 1000
    ); // Subtract 7 days * i weeks
    const prevEndDate = new Date(
      endDate.getTime() - i * 7 * 24 * 60 * 60 * 1000
    );

    // If the previous dates are in a different year, adjust the year in the formatted string
    weeks.push(formatDate(prevStartDate, prevEndDate));
  }

  return weeks;
};

export function updateCurrentQuestion(questions, screen, setCurrentQuestion) {
  const filteredQuestions =
    questions && questions?.filter((ques) => Number(ques?.screen) === screen); // array of all the questions belonging to the same screen
  // sorting the questions based on their ranks

  setCurrentQuestion(
    filteredQuestions?.sort((a, b) => {
      return a?.rank - b?.rank;
    })
  );
}

const WeeklyCheckIn = () => {
  const [weeklyCheckInData, setWeeklyCheckInData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [questionnaireScreen, setQuestionnaireScreen] = useState(1);
  const { clientCode } = useParams();
  const location = useLocation();
  const { name } = location.state || {};
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentPageTwoQuestion, setCurrentPageTwoQuestion] = useState(null);
  const [currentPageThreeQuestion, setCurrentPageThreeQuestion] =
    useState(null);
  const [questionnaireForm, setQuestionnaireForm] = useState(null);
  const [questionnaireLoading, setQuestionnaireLoading] = useState(false);
  const [weeklyCheckinResult, setWeeklyCheckinResult] = useState(null);
  const [week, setWeek] = useState(null);
  const [options, setOptions] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null); // Default to the first option

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    if (week) {
      setSelectedOption(week);
    }
  }, [week]);

  const questionnaireData = [
    //Data to show heading and Background Color on different pages of questionnaire form
    {
      bg: "bg-blue",
      text: "text-blue",
      heading: "Fitness and nutrition update",
      img: "/assets/weekly-checkin-intro-bg.svg",
    },
    {
      bg: "bg-yellow",
      text: "text-yellow",
      heading: "Mind & body check-in",
      img: "/assets/yellow-background.svg",
    },
    {
      bg: "bg-teal",
      text: "text-teal",
      heading: "Measurements & photos",
      img: "/assets/blue-background.svg",
    },
  ];

  useEffect(() => {
    setLoading(true);
    async function getUserData() {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/weekly-review/stats?memberCode=${clientCode}`,
          {
            memberCode: "PRAN",
          }
        );
        if (res.data) {
          setOptions(getPreviousWeeks(res.data.data[0].week));
          setWeek(res.data.data[0].week);
        }
      } catch (err) {
        console.error(err.message);
      } finally {
        setLoading(false);
      }
    }
    getUserData();
  }, []);

  const getUserData = useMemo(
    () => async () => {
      setQuestionnaireLoading(true);
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/weekly-review/questionnaire`
        );
        if (res.data) {
          setQuestionnaireForm(res.data.data);
        }
      } catch (err) {
        console.error(err.message);
      } finally {
        setQuestionnaireLoading(false);
      }
    },
    [] // Dependencies for memoization
  );

  useEffect(() => {
    getUserData(); // Invoke the memoized function
  }, []);

  useEffect(() => {
    if (selectedOption) {
      setError(null);
      setLoading(true);
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/weekly-review?memberCode=${clientCode}&week=${selectedOption}`
          );
          setWeeklyCheckInData(response.data.data.response);

          setWeeklyCheckinResult(response.data.data.report);
          setLoading(false);
        } catch (err) {
          setError(err);
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [clientCode, selectedOption]);

  useEffect(() => {
    // it will update the current question as soon as the screen changes
    questionnaireForm &&
      updateCurrentQuestion(questionnaireForm, 1, setCurrentQuestion);
    updateCurrentQuestion(questionnaireForm, 2, setCurrentPageTwoQuestion);
    updateCurrentQuestion(questionnaireForm, 3, setCurrentPageThreeQuestion);
  }, [questionnaireScreen, questionnaireForm]);

  if (loading) return <div>Loading...</div>;

  return (
    <div>
      <div>
        <label htmlFor="date-range-select">Select a Date Range:</label>

        <select
          id="date-range-select"
          value={selectedOption}
          onChange={handleChange}
        >
          {options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      {error ? (
        <div className="mt-5">No Data Found</div>
      ) : (
        <>
          <div className="border mt-5 border-black rounded-lg">
            <div className="text-[40px] ml-[25px] mt-5">Response</div>
            <div className="flex flex-wrap gap-5">
              {weeklyCheckInData && (
                <>
                  {" "}
                  <QuestionnaireResponseOutput
                    currentQuestion={currentQuestion}
                    questionnaireData={questionnaireData}
                    response={weeklyCheckInData}
                    questionnaireScreen={1}
                  />
                  <QuestionnaireResponseOutput
                    currentQuestion={currentPageTwoQuestion}
                    questionnaireData={questionnaireData}
                    response={weeklyCheckInData}
                    questionnaireScreen={2}
                  />
                  <QuestionnaireResponseOutput
                    currentQuestion={currentPageThreeQuestion}
                    questionnaireData={questionnaireData}
                    response={weeklyCheckInData}
                    questionnaireScreen={3}
                  />
                </>
              )}
            </div>
          </div>
          <div className="text-[40px] mt-10">Report</div>
          {weeklyCheckinResult && (
            <WeeklyCheckinResult weeklyReport={weeklyCheckinResult} />
          )}
        </>
      )}

      {/* {weeklyCheckInData.map((checkIn) => (
        <div key={checkIn._id} className="rounded-lg p-4 border border-gray">
          <div className="flex flex-col sm:flex-row justify-between flex-wrap gap-4">
            <p className="flex flex-col sm:flex-row items-start sm:items-center gap-2">
              <span className="text-xl font-semibold text-black">Date:</span>
              <span className="text-base border border-gray p-2 rounded">
                {new Date(checkIn.createdAt).toLocaleString()}
              </span>
            </p>
            <p className="flex flex-col sm:flex-row items-start sm:items-center gap-2">
              <span className="text-xl font-semibold text-black">Rating:</span>
              <span className="text-base border border-gray p-2 rounded">
                {checkIn.rating}/10
              </span>
            </p>
            <p className="flex flex-col sm:flex-row items-start sm:items-center gap-2">
              <span className="text-xl font-semibold text-black">Weight:</span>
              <span className="text-base border border-gray p-2 rounded">
                {checkIn.weight} KG
              </span>
            </p>
          </div>
          <div className="flex flex-col sm:flex-row mt-6 sm:mt-10 justify-between gap-4">
            <p className="flex flex-col items-start gap-2">
              <span className="text-xl font-semibold text-black">
                Achievement:
              </span>
              <span className="text-base border border-gray p-2 rounded w-full sm:w-auto">
                {checkIn.achievement}
              </span>
            </p>
            <p className="flex flex-col items-start gap-2">
              <span className="text-xl font-semibold text-black">
                Learning:
              </span>
              <span className="text-base border border-gray p-2 rounded w-full sm:w-auto">
                {checkIn.learning}
              </span>
            </p>
          </div>
          {checkIn.imageURLs && checkIn.imageURLs.length > 0 && (
            <div className="mt-6 flex flex-wrap justify-center items-center gap-4">
              {checkIn.imageURLs.map((url, imgIndex) => (
                <div
                  key={imgIndex}
                  className="w-full sm:w-auto h-72 overflow-hidden"
                >
                  <img
                    src={url}
                    alt={`Check-in ${imgIndex + 1}`}
                    className="w-full h-full object-cover rounded"
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      ))} */}
    </div>
  );
};

export default WeeklyCheckIn;
