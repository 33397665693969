import React from "react";

function InputText({ questionCode, response }) {
  return (
    <div className="h-full">
      <div className="mt-2 h-full w-full">
        {questionCode !== "WKR12" && questionCode !== "WKR2" && (
          <div
            disabled
            className="flex border border-black min-h-[58px] w-full items-start rounded-xl bg-white-opacity-08 p-3 text-start font-light placeholder:absolute placeholder:top-3 placeholder:mb-3 placeholder:text-[14px]"
            placeholder="No Answer"
          >
            {" "}
            {questionCode === "WKR11" ||
            questionCode === "WKR10" ||
            questionCode === "WKR9"
              ? (response &&
                  response?.find((elem) => elem.code === questionCode)
                    ?.value?.[0]) ||
                ""
              : (response &&
                  response?.find((elem) => elem.code === questionCode)
                    ?.description) ||
                ""}
          </div>
        )}

        {questionCode === "WKR12" && (
          <input
            type="number"
            disabled
            value={
              (response &&
                response?.find((elem) => elem.code === questionCode)
                  ?.value[0]) ||
              ""
            }
            style={{ borderColor: "#7e87ef" }}
            className=" border border-black flex h-[67px] w-full items-start  rounded-xl bg-white-opacity-08 p-3 text-start font-light placeholder:absolute placeholder:top-3 placeholder:mb-3 placeholder:text-[14px]"
            // onChange={(e) => {
            //   const descriptionValue = e.target.value;
            //   setResponse((prev) => {
            //     // Find the existing entry for the questionCode
            //     const existingIndex = prev.findIndex(
            //       (item) => item.code === questionCode
            //     );

            //     if (existingIndex > -1) {
            //       // Update the existing entry
            //       const updatedResponse = [...prev];
            //       updatedResponse[existingIndex] = {
            //         ...updatedResponse[existingIndex],
            //         value: [descriptionValue],
            //       };
            //       return updatedResponse;
            //     } else {
            //       // Add a new entry with the description
            //       return [
            //         ...prev,
            //         {
            //           code: questionCode,
            //           value: [descriptionValue],
            //           description: "",
            //         },
            //       ];
            //     }
            //   });
            // }}
            placeholder="No Answer"
          />
        )}
      </div>
    </div>
  );
}

export default InputText;
