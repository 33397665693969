import React, { useState } from "react";

function OptionsSecond({ questionCode, options, MCQType, target, response }) {
  const [isTextFieldActive, setTextFieldActive] = useState(false);

  const Option = ({
    questionCode,
    optionID,
    optionValue,
    optionDescription,
    MCQType,
    response,
  }) => {
    return (
      <div
        className={`border-box flex w-full flex-row items-center justify-between rounded-[12px]  text-black border border-black ${
          response &&
          response?.find(
            (elem) =>
              elem?.code === questionCode && elem?.value?.includes(optionID)
          )
            ? "border-1 border  bg-light-blue-300  "
            : "bg-[#3d3d3d]/30"
        }`}
      >
        <div className={`flex w-full flex-col items-center justify-center   `}>
          <p
            className={`text-center text-[12px] pt-3 ${
              response[questionCode]?.find((elem) => elem === optionID)
                ? "text-[#7e87ef]"
                : "text-black"
            }`}
          >
            {optionValue}
          </p>
        </div>
      </div>
    );
  };
  return (
    <div>
      <div className={`grid h-full w-full grid-cols-2 items-center  gap-2 `}>
        {options &&
          options?.map((option, idx) => {
            return (
              <div
                key={option.id}
                className="flex w-full"
                style={{ marginBlock: target === "MED" ? "20px" : "" }}
              >
                <Option
                  MCQType={MCQType}
                  response={response}
                  optionID={option?.id}
                  optionValue={option?.value}
                  optionDescription={option?.description}
                  questionCode={questionCode}
                  key={option?.id}
                />
              </div>
            );
          })}
        {MCQType === "singleChoiceAndOther" && (
          <div className="flex w-full flex-col items-start justify-center">
            <p className="text-[20px] text-[#7e87ef]">
              {target === "MED" ? "If any, " : "Other "}
            </p>
            <input
              type={"text"} //text
              value={
                questionCode && Object.keys(response)?.length > 0
                  ? response[questionCode][0]
                  : ""
              }
              style={{ borderColor: "#7e87ef" }}
              className="textbox"
              placeholder={"Please Specify"}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default OptionsSecond;
