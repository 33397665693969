import { useEffect, useRef, useState } from "react";

const Duration = ({ setDuration }) => {
  const containerRef = useRef(null);
  const itemHeightRef = useRef(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [selectedValue, setSelectedValue] = useState(5);
  const [showTimeInput, setShowTimeInput] = useState(true);
  const [timeChecked, setTimeChecked] = useState(true);

  const items = Array.from({ length: 42 }, (_, i) => {
    if (i === 0 || i === 1 || i === 2 || i === 41 || i === 40 || i === 39) {
      return "";
    } else {
      const number = i - 2;
      return number * 5;
    }
  });

  useEffect(() => {
    if (timeChecked === true) {
      setDuration(`${selectedValue} mins`);
    }
    if (timeChecked === false) {
      setDuration("");
    }
  }, [selectedValue, timeChecked]);

  useEffect(() => {
    // Set the item height after the component mounts
    if (containerRef.current && containerRef.current.firstChild) {
      itemHeightRef.current = containerRef.current.firstChild.offsetHeight;
    }
  }, []);

  const handleScroll = (e) => {
    const container = e.target;
    const itemHeight = itemHeightRef.current;
    const maxScrollIndex = 36; // Corresponds to i === 40

    const currentIndex = Math.round(container.scrollTop / itemHeight);

    if (currentIndex < 0) {
      container.scrollTop = 0;
      setScrollPosition(0);
      setSelectedValue(items[0]);
    } else if (currentIndex > maxScrollIndex) {
      container.scrollTop = itemHeight * maxScrollIndex;
      setScrollPosition(maxScrollIndex);
      setSelectedValue(items[maxScrollIndex + 3]);
    } else {
      setScrollPosition(currentIndex);
      const selectedIndex = currentIndex + 3;
      setSelectedValue(items[selectedIndex]);
    }
  };

  return (
    <div>
      <div className="mt-[30px] font-sfpro text-[16px] ">
        <label>
          <input
            className="w-4 h-4 pt-3"
            type="checkbox"
            checked={timeChecked}
            onChange={(e) => setTimeChecked(e.target.checked)}
          />
        </label>{" "}
        Duration
      </div>
      {timeChecked && (
        <div className="relative mt-1 w-full rounded-lg border border-black-opacity-25 py-3 pt-0">
          <div
            className="picker-container bg-gray w-full rounded-xl bg-white-opacity-08 px-[6px]"
            ref={containerRef}
            onScroll={handleScroll}
          >
            {items.map((item, index) => {
              const position = (index % items.length) - scrollPosition - 3;

              // console.log(index, items.length, scrollPosition, position);

              let className = "flex justify-center picker-item";
              if (position === 0) {
                className += " selected pl-[53px] ";
              } else if (position === 1 || position === -1) {
                className += " adjacent";
              } else if (position === 2 || position === -2) {
                className += " far";
              } else if (position === 3 || position === -3) {
                className += " veryfar";
              }

              return (
                <div
                  onClick={
                    position === 0 ? () => setShowTimeInput(false) : undefined
                  }
                  key={index}
                  className={className}
                >
                  {item}
                  {"    "}
                  {position === 0 && <div className="pl-5">mins</div>}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Duration;
